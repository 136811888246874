.user-name{
    font-size: 34px;
    color: black;
    font-family: "Muli-Regular";
    display: flex;
    justify-content: center;
}

p{
    font-size: 16px;
    font-family: "Muli-Regular";
    
}

.user-role{
    font-size: 24px;
    color: black;
    font-family: "Catamaran-Regular";
    display: flex;
    justify-content: center;
    margin-bottom: 2.188rem;
}

.log-out-button{
    margin-top: 10rem;
}

.password-field{
    width: 22rem;
}

.password-row{
    display: flex;
    justify-content: space-between;
    padding-right: 0rem;
}

.change-password-button{
    margin-right: 0px !important;
    width: 11rem;
    height: 2.25rem;
}

.profile-section-body{
    width: 72.5rem;
    margin: 1.5rem auto !important;
    min-height: 85vh;
}